/** @format */
import { useEffect, useRef, useState } from "react";
import useSWR from "swr";

const API_URL = process.env.REACT_APP_API_URL;

const fetcher = async (url) => {
  const res = await fetch(url);

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

const Playlist_2_2 = {
  layout: { rows: 2, columns: 2, flow: "row" },
  content: [
    {
      id: "3dAg",
      bg: "bg-gray-100",
      rows: [1, 1],
      cols: [1, 1],
      type: "image",
      url: "https://editorial.uefa.com/resources/026b-12bbbeee89c5-609a7db70bb6-1000/format/wide1/fbl-euro-2020-2021-match51-ita-eng-trophy.jpeg?imwidth=2048",
    },
    {
      id: "21dp",
      bg: "bg-gray-200",
      rows: [2, 1],
      cols: [2, 1],
      type: "html",
      body: '<div classname="text-black bg-white p-24">41 31</div>',
    },
    {
      id: "87hn",
      bg: "bg-gray-500",
      rows: [2, 1],
      cols: [1, 1],
      type: "html",
      body: '<div classname="text-black bg-white p-24">aa</div>',
    },
  ],
};

const Playlist_2_3 = {
  rows: 2,
  columns: 3,
  content: [
    {
      id: "311",
      bg: "bg-gray-100",
      rows: [1, 1],
      cols: [1, 2],
      type: "image",
      url: "https://editorial.uefa.com/resources/026b-12bbbeee89c5-609a7db70bb6-1000/format/wide1/fbl-euro-2020-2021-match51-ita-eng-trophy.jpeg?imwidth=2048",
    },
    {
      id: "9933",
      bg: "bg-gray-200",
      rows: [1, 2],
      cols: [3, 1],
      type: "html",
      body: '<div classname="text-red bg-white p-24">41 31</div>',
    },
    {
      id: "237n",
      bg: "bg-gray-500",

      rows: [2, 1],
      cols: [1, 2],
      type: "video",
      url: "https://www.w3schools.com/html/mov_bbb.mp4",
      width: 400,
    },
  ],
};

function GridUI() {
  const { data } = useSWR(`${API_URL}playlist`, fetcher, {
    revalidateOnFocus: true,
    refreshInterval: 10000,
  });

  const [playlist, setPlaylist] = useState(Playlist_2_3);

  // useEffect(() => {

  useEffect(() => {
    if (data && data.playlist) {
      console.log(data.playlist.playlist);
      setPlaylist(data.playlist.playlist);
    }
  }, [data]);

  return (
    <div
      className={`block h-screen w-screen overflow-hidden bg-black text-white grid  grid-cols-${playlist.columns} grid-rows-${playlist.rows} grid-flow-col`}
    >
      {playlist.content.map((item, i) => (
        <div
          key={`${i}${item.cols[0]} ${item.cols[1]} ${item.rows[0]} ${item.rows[1]}`}
          className={`col-start-${item.cols[0]} col-span-${item.cols[1]} row-start-${item.rows[0]} row-span-${item.rows[1]} ${item.bg} inline-flex items-center justify-center border-gray-700 border-2 text-xl`}
        >
          <div className="inline-block w-full h-full ">
            {item.type === "image" && (
              <img
                src={item.url}
                alt=""
                className="inline-block w-full h-full object-contain"
              />
            )}
            {item.type === "url" && (
              <div className="inline-block w-full h-full " style={{}}>
                <iframe height="100%" width="100%" src={item.url} title="23" />
              </div>
            )}
            {item.type === "video" && (
              <video
                src={item.url}
                autoPlay={true}
                loop={true}
                muted={true}
                type="video/mp4"
                width="100%"
                className="inline-block w-full h-full object-contain"
              />
            )}
            {item.type === "html" && (
              <div
                dangerouslySetInnerHTML={{
                  __html: item.body,
                }}
              />
            )}
          </div>
        </div>
      ))}
      {/* {playlist.content.map((content, idx) => (
        <div
          key={content}
          className={`col-start-${content.cols[0]} col-span-${content.cols[1]} row-start-${content.rows[0]} row-span-${content.rows[1]} ${content.bg}`}
        >
          {content.type === "image" && <img src={content.url} alt="" />}
          {content.type === "video" && (
            <video
              src={content.src}
              autoPlay={true}
              loop={true}
              muted={true}
              type="video/mp4"
              width="100%"
            />
          )}
          {content.type === "html" && (
            <div dangerouslySetInnerHTML={{ __html: content.body }} />
          )}
          {content.type === "url" && (
            <iframe
              // width={selectedBanner.split('x')[0]}
              // height={selectedBanner.split('x')[1]}
              src={content.url}
              scrolling="no"
              title="banner"
              // onLoad={() => setIframeLoaded(true)}
              // style={{
              //   border: 'none',
              //   visibility: `${iframeLoaded ? 'visible' : 'hidden'}`
              // }}
            />
          )}
        </div>
      ))} */}
    </div>
  );
}

export default GridUI;
