/** @format */

import { useEffect, useState } from "react";
import { useWindowSize } from "./utils/windowSize";
import { useAppContext } from "./store/app-context";

import QRCode from "react-qr-code";
import useSWR from "swr";

const API_URL = process.env.REACT_APP_API_URL;

const fetcher = async (url) => {
  const res = await fetch(url);

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

export default function Register(props) {
  const { onRegistered } = props;
  const size = useWindowSize();
  const [code, setCode] = useState(null);
  const [auth, setAuth] = useState(null);

  const { data } = useSWR(
    `${API_URL}register/?code=${code}&auth=${encodeURIComponent(auth)}&width=${
      size.width
    }&height=${size.height}`,
    fetcher,
    { revalidateOnFocus: false, refreshInterval: 10000 }
  );

  const { dispatch } = useAppContext();
  //   const { state } = useAppContext();

  useEffect(() => {
    if (data) {
      const { code, auth, registered } = data;
      setCode(code);
      setAuth(auth);
      if (registered) {
        console.log("registered");
        onRegistered();
      }
    }
  }, [data]);

  return (
    <div className="block h-screen w-screen flex flex-col justify-center items-center overflow-hidden bg-black text-white">
      <img
        className="fixed top-0"
        style={{
          left: 124,
          width: 132,
          height: 132,
        }}
        src="/logo.png"
        alt="Fortuna Logo"
      />
      <div className="fixed left-0 bottom-0 text-xl mb-8 px-16 py-8">
        <span class="inline-block h-3 w-3">
          <span class="animate-ping absolute inline-flex h-6 w-6 rounded-full bg-purple-400 opacity-80"></span>
          <span class="relative inline-flex rounded-full h-3 w-3 bg-purple-400"></span>
        </span>

        <p>Digital Signage Client Demo</p>
        <p className="text-gray-400">
          Screen size: {size.width}x{size.height}
        </p>
      </div>
      {!code && <div>Connecting...</div>}
      {code && (
        <>
          <QRCode value={code} bgColor="#ffdb01" />
          <div
            className="text-9xl mt-16 text-yellow tracking-widest font-body"
            // style={{ color: "#ffdb01" }}
          >
            {code}
          </div>
        </>
      )}
      <div
        c
        style={{
          width: "100vw",
          height: 0,
          borderBottom: "calc(100vw * 0.105104) solid #ffdb01",
          borderLeft: "100vw solid transparent",
          position: "fixed",
          bottom: 0,
          left: 0,
        }}
      />
    </div>
  );
}
