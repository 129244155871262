/** @format */
import { useEffect, useState } from "react";
import { getCookie, setCookie } from "./utils/cookies";
import { AppProvider, useAppContext } from "./store/app-context";
import GridUI from "./GridUI";
import Register from "./Register";

function App() {
  const [authToken, setAuthToken] = useState(null);
  useEffect(() => {
    console.log("App started.");
    const cookieToken = getCookie("auth");
    if (cookieToken) {
      setAuthToken(cookieToken);
    }
  }, []);

  if (!authToken) {
    return (
      <AppProvider>
        <Register
          onRegistered={() => {
            setAuthToken(true);
          }}
        />
      </AppProvider>
    );
  }

  return (
    <AppProvider>
      <GridUI />
    </AppProvider>
  );
}

export default App;
