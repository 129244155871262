/** @format */

const getCookie = (name) => {
  const cookies = document.cookie.split("; ");
  if (cookies.length === 0) {
    return undefined;
  }
  return cookies.find((row) => row.startsWith(name))?.split("=")[1];
};

const setCookie = (name, value) => {
  document.cookie = `${name}=${value}; expires=Sun, 16 Jul 3567 06:23:41 GMT; path=/`;
};

export { getCookie, setCookie };
